var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6","lg":"6","xl":"6"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('div',{staticClass:"mr-5"},[_c('h3',[_vm._v("Sales Representatives")])]),_c('v-menu',{attrs:{"nudge-width":180,"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"gradient-bg2",attrs:{"small":"","dark":""}},Object.assign({}, menu, tooltip)),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(" Show Credits for Sales Incentives ")])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-toolbar',{staticClass:"gradient-bg white--text",attrs:{"dense":"","height":"30px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.menu = false}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-close ")])],1)],1),_c('v-card',{staticClass:"elevation-0 overflow-hidden"},[_c('v-card-text',{staticClass:"mt-n3",attrs:{"align":"center"}},[_c('span',{staticClass:"mt-n4",staticStyle:{"font-size":"16px"}},[_c('strong',[_vm._v(" Incentives ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8","align":"left"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" First Incentive ")]),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" Second Incentive ")])]),_c('v-col',{attrs:{"cols":"12","md":"4","align":"left"}},[_c('span',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(': ' + _vm.first_incentive)}}),_c('br'),_c('span',{staticClass:"font-weight-medium",domProps:{"textContent":_vm._s(': ' + _vm.second_incentive)}})])],1)],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 gradient-bg2",attrs:{"small":"","dark":""},on:{"click":function($event){_vm.UpdateIncentiveDialog = true}}},on),[_c('v-icon',[_vm._v(" mdi-update ")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Update Sales Incentives")])])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4","lg":"4","xl":"4"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-text-field',{staticClass:"mt-4 mr-2",attrs:{"label":"Search","append-icon":"mdi-magnify","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"gradient-bg white--text",attrs:{"small":""},on:{"click":function($event){_vm.salesRepDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Add Service Representative")])])],1)],1)],1),_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.SalesRepHeaders,"items":_vm.SalesRepItems,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{on:{"click":function($event){return _vm.delete_member_details(item)}}},[_c('v-icon',_vm._g({staticClass:"red--text",attrs:{"small":""}},on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("Delete this Sales Rep?")])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No Sales Representative(s) Listed ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true}],null,true)})],1)],1),_c('AddSalesRep',{attrs:{"salesRepDialog":_vm.salesRepDialog},on:{"close_dialog":_vm.call_get}}),_c('DeleteDialog',{attrs:{"confDeleteDialog":_vm.confDeleteDialog,"deleteText":_vm.deleteText,"deleteIcon":_vm.deleteIcon,"deleteColor":_vm.deleteColor,"deleteLoading":_vm.deleteLoading},on:{"close_conf_delete_dialog":function($event){_vm.confDeleteDialog = false},"success_conf_delete":_vm.deleteSuccessAction}}),_c('UpdateIncentiveDialog',{attrs:{"updateIncentiveDialog":_vm.UpdateIncentiveDialog,"current_first_incentive_value":_vm.first_incentive,"current_second_incentive_value":_vm.second_incentive},on:{"close_dialog":_vm.call_up_get}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="mt-5">
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-row no-gutters>
      <v-col cols="12" md="6" sm="6" lg="6" xl="6">
        <v-toolbar dense elevation="0">
          <div class="mr-5">
            <h3>Sales Representatives</h3>
          </div>
          <v-menu
            v-model="menu"
            :nudge-width="180"
            offset-y
            :close-on-content-click="false"
          >
            <template #activator="{ on: menu }">
              <v-tooltip bottom color="primary">
                <template #activator="{ on: tooltip }">
                  <v-btn
                    class="gradient-bg2"
                    small
                    dark
                    v-on="{ ...menu, ...tooltip }"
                  >
                    <v-icon> mdi-information </v-icon>
                  </v-btn>
                </template>
                <span class="white--text">
                  Show Credits for Sales Incentives
                </span>
              </v-tooltip>
            </template>
            <v-toolbar dense height="30px" class="gradient-bg white--text">
              <v-spacer />
              <v-btn icon dark @click="menu = false">
                <v-icon x-small> mdi-close </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card class="elevation-0 overflow-hidden">
              <v-card-text align="center" class="mt-n3">
                <span style="font-size: 16px" class="mt-n4">
                  <strong> Incentives </strong>
                </span>
                <v-row>
                  <v-col cols="12" md="8" align="left">
                    <span class="font-weight-medium"> First Incentive </span>
                    <br />
                    <span class="font-weight-medium"> Second Incentive </span>
                  </v-col>
                  <v-col cols="12" md="4" align="left">
                    <span
                      class="font-weight-medium"
                      v-text="': ' + first_incentive"
                    >
                    </span>
                    <br />
                    <span
                      class="font-weight-medium"
                      v-text="': ' + second_incentive"
                    >
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                v-on="on"
                class="mx-2 gradient-bg2"
                dark
                @click="UpdateIncentiveDialog = true"
              >
                <v-icon> mdi-update </v-icon>
              </v-btn>
            </template>
            <span class="white--text">Update Sales Incentives</span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense elevation="0">
          <v-text-field
            label="Search"
            class="mt-4 mr-2"
            v-model="search"
            append-icon="mdi-magnify"
            dense
          ></v-text-field>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="salesRepDialog = true"
                class="gradient-bg white--text"
                v-on="on"
                small
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Add Service Representative</span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-data-table
          :headers="SalesRepHeaders"
          :items="SalesRepItems"
          :search="search"
          dense
        >
          <template v-slot:[`item.Actions`]="{ item }">
            <!-- <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click="open_sales_rep_dasboard(item)">
                  <v-icon v-on="on" small class="primary--text mr-3">
                    mdi-eye
                  </v-icon>
                </a>
              </template>
              <span class="white--text">View Details</span>
            </v-tooltip> -->
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a @click="delete_member_details(item)">
                  <v-icon v-on="on" small class="red--text">
                    mdi-delete
                  </v-icon>
                </a>
              </template>
              <span class="white--text">Delete this Sales Rep?</span>
            </v-tooltip>
          </template>
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Sales Representative(s) Listed
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <AddSalesRep :salesRepDialog="salesRepDialog" @close_dialog="call_get" />
    <DeleteDialog
      :confDeleteDialog="confDeleteDialog"
      @close_conf_delete_dialog="confDeleteDialog = false"
      :deleteText="deleteText"
      :deleteIcon="deleteIcon"
      :deleteColor="deleteColor"
      @success_conf_delete="deleteSuccessAction"
      :deleteLoading="deleteLoading"
    />
    <UpdateIncentiveDialog
      :updateIncentiveDialog="UpdateIncentiveDialog"
      @close_dialog="call_up_get"
      :current_first_incentive_value="first_incentive"
      :current_second_incentive_value="second_incentive"
    />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import AddSalesRep from "@/components/Dialogs/SalesRepresentative/AddSalesRep.vue";
import UpdateIncentiveDialog from "@/components/Dialogs/SalesRepresentative/UpdateIncentives.vue";

import { API, graphqlOperation } from "aws-amplify";
import { ListSalesRep } from "@/graphql/queries.js";
import DeleteDialog from "@/components/Dialogs/ConfirmationDialogs/DeleteDialog.vue";
import { DeleteReactiveSalesRep } from "@/graphql/mutations.js";
import { getSalesReferal } from "@/graphql/queries.js";

export default {
  components: {
    Overlay,
    SnackBar,
    AddSalesRep,
    DeleteDialog,
    UpdateIncentiveDialog,
  },
  data: () => ({
    deleteLoading: false,
    confDeleteDialog: false,
    deleteColor: "",
    deleteIcon: "",
    deleteText: "",
    menu: false,
    salesRepDialog: false,
    overlay: false,
    SnackBarComponent: {},
    search: "",
    SalesRepHeaders: [
      {
        text: "Name",
        value: "user_name",
      },
      {
        text: "Referral Code",
        value: "referral_code",
      },
      {
        text: "E-Mail",
        value: "user_email_id",
      },
      {
        text: "Contact Number",
        value: "user_full_contact_number",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    SalesRepItems: [],
    first_incentive: 0,
    second_incentive: 0,
    UpdateIncentiveDialog: false,
  }),
  mounted() {
    this.get_sales_reps_list();
    this.get_sales_incentives();
  },
  methods: {
    async get_sales_reps_list() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(ListSalesRep, {
            input: {
              user_status: "ACTIVE",
            },
          })
        );
        this.SalesRepItems = JSON.parse(result.data.ListSalesRep).Items;
        this.overlay = false;
        this.$forceUpdate();
        // this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.overlay = false;
      }
    },
    delete_member_details(item) {
      this.deleteUserItem = item;
      this.confDeleteDialog = true;
      this.deleteText = `Are you sure you want to Delete the Sales Representative ${item.user_name} ?`;
      this.deleteIcon = "mdi-account-off";
      this.deleteColor = "red";
    },
    deleteSuccessAction() {
      this.deleteLoading = true;
      this.deactivate_reactivate_members(this.deleteUserItem);
    },
    async deactivate_reactivate_members(item) {
      this.deleteLoading = true;
      try {
        await API.graphql(
          graphqlOperation(DeleteReactiveSalesRep, {
            input: {
              user_type: "deactive",
              user_email_id: item.user_email_id,
            },
          })
        ).then((res) => {
          this.$store.commit("snackbar/showMessage", {
            content: JSON.parse(res.data.DeleteReactiveSalesRep).Message,
            color: "green",
            top: true,
          });
          this.get_sales_reps_list();
          this.deleteLoading = false;
          this.confDeleteDialog = false;
        });
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
          top: true,
        });
        this.deleteLoading = false;
      }
    },
    call_get() {
      this.salesRepDialog = false;
      this.get_sales_reps_list();
    },
    call_up_get() {
      this.UpdateIncentiveDialog = false;
      this.get_sales_incentives();
    },

    async get_sales_incentives() {
      this.first_incentive = 0;
      this.second_incentive = 0;
      try {
        let result = await API.graphql(
          graphqlOperation(getSalesReferal, {
            input: {},
          })
        );
        this.first_incentive = JSON.parse(
          result.data.getSalesReferal
        ).Items[0].first_incentive;
        this.second_incentive = JSON.parse(
          result.data.getSalesReferal
        ).Items[0].second_incentive;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style></style>

<template>
    <div>
        <v-dialog v-model="updateIncentiveDialog" persistent max-width="500px" transition="dialog-transition">
            <v-toolbar dense class="elevation-0 gradient-bg white--text">
                <v-toolbar-title>
                    Update Sales Incentives
                </v-toolbar-title>
                <v-spacer />
                <v-btn dark @click="close_dialog()" icon>
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="overflow-hidden">
                <div class="ma-5">
                    <v-row no-gutters>
                        <v-col cols="12" md="4" class="mt-2">
                            <span class="font-weight-regular" style="font-size:17px"> First Incentive :</span>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-select v-model="first_incentive" :items="incentive_items" label="Update Sales Incentive"
                                dense outlined>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" md="4" class="mt-2">
                            <span class="font-weight-regular" style="font-size:17px"> Second Incentive :</span>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-select v-model="second_incentive" :items="incentive_items" label="Update Sales Incentive"
                                dense outlined>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row justify="center" no-gutters>
                        <v-btn :loading="isLoading" dark color="gradient-bg" @click="update_incentives()"> Update
                        </v-btn>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>
        <SnackBar :SnackBarComponent="SnackBarComponent" />
    </div>
</template>

<script>
    import SnackBar from "@/components/SnackBar.vue";

    import {
        API,
        graphqlOperation,
    } from "aws-amplify";
    import {
        UpdateSalesReferralCreditsToOrg
    } from "@/graphql/mutations.js";

    export default {
        components: {
            SnackBar,
        },
        props: {
            updateIncentiveDialog: Boolean,
            current_first_incentive_value: Number,
            current_second_incentive_value: Number,
        },
        data: () => ({
            SnackBarComponent: {},
            overlay: false,
            first_incentive: 0,
            second_incentive: 0,
            isLoading: false,

            incentive_items: [{
                    text: "250 Credits",
                    value: 250,
                },
                {
                    text: "500 Credits",
                    value: 500,
                }
            ]

        }),
        watch: {
            updateIncentiveDialog(val) {
                if (val == true) {
                    this.first_incentive = this.current_first_incentive_value;
                    this.second_incentive = this.current_second_incentive_value;
                }
            }
        },
        methods: {
            close_dialog() {
                this.$emit('close_dialog');
            },

            async update_incentives() {
                this.isLoading = true;
                try {
                    await API.graphql(
                        graphqlOperation(
                            UpdateSalesReferralCreditsToOrg, {
                                input: {
                                    first_incentive: this.first_incentive,
                                    second_incentive: this.second_incentive,
                                }
                            }
                        )
                    ).then((res) => {
                        this.SnackBarComponent = {
                            SnackbarVmodel: true,
                            SnackbarColor: "green",
                            Top: true,
                            SnackbarText: JSON.parse(res.data.UpdateSalesReferralCreditsToOrg).Message,
                        }
                    });
                    this.isLoading = false;
                    this.$emit('close_dialog');
                } catch (err) {
                    console.log(err);
                    this.isLoading = false;
                    this.SnackBarComponent = {
                        SnackbarVmodel: true,
                        SnackbarColor: "red",
                        Top: true,
                        SnackbarText: "System Error",
                    };
                }
            }

        }
    }
</script>


<style>

</style>
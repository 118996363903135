<template>
  <div>
    <v-dialog v-model="salesRepDialog" persistent width="600px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title>Create Sales Representative</v-toolbar-title>
        <v-spacer />
        <v-btn @click="close_dialog()" text
          ><v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-form ref="form">
          <v-row class="mt-5" no-gutters>
            <v-col cols="12" md="12" sm="12" lg="12" xl="12" xs="12">
              <v-toolbar dense class="elevation-0">
                <v-text-field
                  label="Name *"
                  v-model="member_name"
                  :rules="member_name_rules"
                  dense
                  outlined
                ></v-text-field>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row class="mx-4" no-gutters>
            <v-col cols="12" md="4" sm="12" lg="4" xl="4">
              <v-autocomplete
                label="Country Code *"
                v-model="country_code"
                :items="CountryList"
                item-value="phonecode"
                item-text="name"
                dense
                :rules="country_code_rules"
                outlined
                class="mr-2"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.flag }} ({{
                    item.phonecode.toString().includes("+") == false
                      ? "+" + item.phonecode
                      : item.phonecode
                  }})
                </template>
                <template slot="item" slot-scope="{ item }">
                  {{ item.flag }} {{ item.name }} ({{ item.phonecode }})
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" lg="8" xl="8" md="8">
              <v-text-field
                v-model="contact_number"
                dense
                outlined
                label="Contact Number *"
                :rules="contact_number_rules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-n2" no-gutters>
            <v-col cols="12" md="12" sm="12" lg="12" xl="12">
              <v-text-field
                label="E-Mail *"
                v-model="email"
                dense
                :rules="email_rules"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="ma-0 pa-0">
            <v-card-text align="center">
              <v-btn
                :loading="isLoading"
                @click="validate()"
                small
                class="gradient-bg white--text"
              >
                <v-icon class="mr-2">mdi-plus</v-icon> Create</v-btn
              >
            </v-card-text>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { Country } from "country-state-city";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { CreateSalesRep } from "@/graphql/mutations.js";
export default {
  components: {
    Overlay,
    SnackBar,
  },
  props: {
    salesRepDialog: Boolean,
  },
  data: () => ({
    SnackBarComponent: {},
    overlay: false,
    isLoading: false,
    member_name: "",
    country_code: "",
    CountryList: [],
    email: "",
    contact_number: "",
    member_name_rules: [
      (x) =>
        /^[a-z\sA-Z]{3,30}$/.test(x) ||
        "Name must be between 3 & 30 characters and Should not contain special characters",
      (x) => x.trim() != "" || "Member Name cannot be empty",
    ],
    country_code_rules: [(x) => x.trim() != "" || "Select Country Code"],
    contact_number_rules: [
      (x) => x.trim() != "" || "Contact Number cannot be empty",
      (v) => /^[0-9]{8,12}$/.test(v) || "Contact Number must be valid",
    ],
    email_rules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
  }),
  watch: {
    salesRepDialog(val) {
      if (val == true) {
        this.CountryList = Country.getAllCountries();
        fetch(
          "https://api.geoapify.com/v1/ipinfo?apiKey=7dc7ae8d1cc54ab2ae3753110b2a7726"
        )
          .then((resp) => resp.json())
          .then((userLocationData) => {
            this.CountryName = userLocationData.country.name;
            var self = this;
            self.CountryList.forEach(function (CountryIDX) {
              if (CountryIDX.name == userLocationData.country.name) {
                self.country_code = CountryIDX.phonecode;
              }
            });
          });
      }
    },
  },
  methods: {
    async add_member() {
      this.isLoading = true;
      try {
        var inputParams = {
          user_name: this.member_name,
          user_contact_number: this.contact_number,
          user_country_code:
            this.country_code.toString().includes("+") == false
              ? "+" + this.country_code
              : this.country_code,
          user_email_id: this.email,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(CreateSalesRep, {
            input: inputParams,
          })
        ).then((response) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(response.data.CreateSalesRep).Message,
          };
          this.isLoading = false;
          this.$refs.form.reset();
          this.close_dialog();
        });
      } catch (e) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: e.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.add_member();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter all Fields",
        };
      }
    },
    close_dialog() {
      this.$refs.form.reset();
      this.$emit("close_dialog");
    },
  },
};
</script>

<style></style>
